const [w, d, l, mq] = [window, document, location, window.matchMedia('(max-width: 900px)')];

const insertMenu = () => {
  const data = {
    url: '/reform/?mode=detail&article=47',
    name: '選ばれる７の理由'
  }
  const $menu = {
    pc: $(`<th><a href="${data.url}" title="${data.name}">${data.name}</a></th>`),
    sp: $(`<li><a href="${data.url}">${data.name}</a></li>`)
  }
  $.addGlobalNav([
   [ $menu, 1, 1 ]
  ]);

  if( location.pathname == '/reform/' && location.search.search(/article=47[^\d+]?/) != -1 && !mq.matches ){
    document.querySelector('#gmenu .mreform').classList.remove('mreform')
    document.querySelector('#gmenu th:nth-child(2) a').classList.add('mreform')
  }
}

window.addEventListener('DOMContentLoaded',insertMenu);


$.addGlobalNav = (items,option={}) => {
  const opt = $.extend(true, {
    menus: {
      'pc-gmenu':$('#gmenu th'),
      'pc-fmenu':$('#footer .footerlink tr:first-child>td>ul>li'),
      'sp-gmenu':$('.ui-mobile #header_menu ul.contentslist'),
      'sp-fmenu':$('.ui-mobile .homeContents ul.homeContentslist')
    }
  }, option);
  $.each(items,function(key,item){
    $.each(opt.menus,function(key,$menu){
      var $o = $(is_mobile()? item[0].sp: item[0].pc).clone(true),
          order = item[1];
      switch(key){
        case 'pc-gmenu':
        case 'pc-fmenu':
          if(!is_mobile()){
            if(order !== false) $menu.eq(order - 1).after( $o );
          }
          break;
        case 'sp-gmenu':
        case 'sp-fmenu':
          if(is_mobile()){
            $('a',$o).prepend(' ').addClass('icon-arrow-r ui-btn');
            order = item[2];
            if(order !== false){
              $menu.find('li:eq('+(order - 1)+')').after( $o );
              // $menu.listview();
            }
          }
          break;
        default:
      }
    });
  });
}
